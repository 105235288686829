import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import { Link } from 'gatsby'
import Img1 from '../images/new/blog/oficina-inclusiva.png'
import Img2 from '../images/new/blog/na-escola-a-gente-controi.png'
import Img3 from '../images/new/blog/post/image-8.jpeg'
import Img4 from '../images/new/blog/post/image-5.jpeg'
import Img5 from '../images/new/blog/post/image.jpeg'
import Img6 from '../images/new/blog/post/jornada/img-2.jpg'

import { 
  section,
  HeaderBanner,
  container,
  content,
  columns,
  column,
} from '../components/styles/Base.styles'

const BlogPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="BLOG | Um reino de aprendizado e conhecimento" />
    <NavBar />
    {/* <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div> */}
    <div css={[section, content]} style={{ paddingBottom: 0, marginTop: 112 }}>
      <div css={container}>
        <h1>Blog</h1>
        <div css={columns}>
          <div css={column}>
           <Link to='/jornada-multissensorial-de-2023'>
              <h2 style={{ fontWeight: 'normal', marginBottom: 16 }}>26 Mai 2023</h2>
              <h2 style={{ fontSize: 20, marginBottom: 16 }}>Jornada Multissensorial de 2023</h2>
              <p>Aconteceu a primeira edição da Jornada Multissensorial na manhã de sábado, 20/05/2023, com o engajamento das famílias Kingdom. O evento...</p>
              <img src={Img6} alt='' style={{ width: '98%', marginBottom: 0 }} />
           </Link>
          </div>
          <div css={column}>
           <Link to='/jonas-ribeiro-alegrou-o-dia-na-kingdom-school-unidade-kids'>
              <h2 style={{ fontWeight: 'normal', marginBottom: 16 }}>31 Jan 2023</h2>
              <h2 style={{ fontSize: 20, marginBottom: 16 }}>Jonas Ribeiro alegrou o dia na Kingdom School (unidade Kids)</h2>
              <p>Quais as vantagens da leitura para as crianças? Imensas, pois cientificamente a leitura estimula o desenvolvimento cerebral, além de aumentar bastante...</p>
              <img src={Img5} alt='' style={{ width: '98%', marginBottom: 0 }} />
           </Link>
          </div>
        </div>
        <div style={{ marginBottom: 80 }} />
        <div css={columns}>
          <div css={column}>
           <Link to='/matematica-de-singapura'>
              <h2 style={{ fontWeight: 'normal', marginBottom: 16 }}>29 Jan 2023</h2>
              <h2 style={{ fontSize: 20, marginBottom: 16 }}>Matemática de Singapura</h2>
              <p>Um dos conceitos da matemática de Singapura é proporcionar aos estudantes uma forma de absorver a modalidade a partir de três pontos importantes e...</p>
              <img src={Img3} alt='' style={{ width: '98%', marginBottom: 0 }} />
           </Link>
          </div>
          <div css={column}>
           <Link to='/a-leitura-e-o-despertar-para-o-mundo-da-imaginacao'>
              <h2 style={{ fontWeight: 'normal', marginBottom: 16 }}>30 Jan 2023</h2>
              <h2 style={{ fontSize: 20, marginBottom: 16 }}>A leitura e o despertar para o mundo da imaginação</h2><br />
              <p>Um programa de leitura com novidades é capaz de transformar a vivência na escola em um lugar de estímulo à imaginação, gosto pelas histórias e...</p>
              <img src={Img4} alt='' style={{ width: '98%', marginBottom: 0 }} />
           </Link>
          </div>
        </div>
        <div style={{ marginBottom: 80 }} />
        <div css={columns}>
          <div css={column}>
           <Link to='/coletanea-2021-na-escola-a-gente-constroi-muitas-historias'>
              <h2 style={{ fontWeight: 'normal', marginBottom: 16 }}>11 Ago 2022</h2>
              <h2 style={{ fontSize: 20, marginBottom: 16 }}>Coletânea 2021 - “Na escola, a gente constrói muitas histórias.”</h2><br />
              <p>Várias são as vantagens que a leitura traz para uma criança. O aumento de seu repertório, a identificação com os personagens da narrativa, a expansão...</p>
              <img src={Img2} alt='' style={{ width: '98%', marginBottom: 0 }} />
           </Link>
          </div>
          <div css={column}>
           <Link to='/kingdom-promove-oficina-de-arte-inclusiva-em-homenagem-ao-bicentenario-da-independencia-e-aos-62-anos-de-brasilia'>
              <h2 style={{ fontWeight: 'normal', marginBottom: 16 }}>6 Jun 2022</h2>
              <h2 style={{ fontSize: 20, marginBottom: 16 }}>Kingdom promove oficina de arte inclusiva em homenagem ao bicentenário da independência e aos 62 anos de Brasília</h2>
              <p>Em parceria com o Sebrae e a Secretaria de Educação, escola recebeu o artista Carlos Bracher e 80 alunos surdos e de baixa visão da Rede Pública do DF...</p>
              <img src={Img1} alt='' style={{ width: '98%', marginBottom: 0 }} />
           </Link>
          </div>
        </div>
        <div style={{ paddingBottom: 112 }} />
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default BlogPage
